<template>
  <div class="google-auth">
    <div class="google-NEW" v-if="connectionType === 'New'">
      <div class="form-group" v-if="!accounts.length">
        <button
          class="btn btn-primary btn-auth"
          @click.prevent="getGoogleAuth"
          :disabled="messageEventStatus"
        >
          Authorisation Google
        </button>
      </div>
      <div class="form-group" v-if="accounts.length">
        <label class="col-form-label">Google Account</label>
        <div class="row">
          <div class="col-sm-7">
            <div class="form-group">
              <select
                class="form-control"
                :class="{
                  'is-invalid': errorMsg.getAccounts
                }"
                v-model="googleAccountId"
              >
                <option
                  v-for="option of accounts"
                  :key="option.id"
                  :value="option.id"
                  >{{ option.name }}</option
                >
              </select>
            </div>
          </div>
          <div class="col-sm-5">
            <button
              class="btn btn-primary btn-addAccount"
              v-b-tooltip.hover
              title="Add Account"
              @click.prevent="getGoogleAuth"
              :disabled="messageEventStatus"
            >
              <span class="material-icons" style="font-size:19px;">add</span>
            </button>
          </div>
        </div>
        <div class="invalid-feedback" v-show="errorMsg.getAccounts">
          {{ errorMsg.getAccounts }}
        </div>
      </div>

      <div class="form-group" v-if="accounts.length">
        <label class="col-form-label">SA360 Account</label>
        <div class="row form-group">
          <div class="col-sm-7">
            <div class="form-group mb-0 loading-container">
              <select
                class="form-control"
                :class="{
                  'is-invalid': errorMsg.getSa360Accounts
                }"
                v-model="sa360AccountId"
              >
                <option
                  v-for="option of sa360Accounts"
                  :key="option.id"
                  :value="option.id"
                  >{{ option.accountName }}</option
                >
              </select>
              <InputLoading :active.sync="adAccountLoading" :size="20" />
            </div>
          </div>
        </div>
        <div class="invalid-feedback" v-show="errorMsg.getSa360Accounts">
          {{ errorMsg.getSa360Accounts }}
        </div>
      </div>

      <div
        class="invalid-feedback"
        :class="
          authAccount.status === 'success' ? 'text-success' : 'text-danger'
        "
        v-show="authAccount.msg"
      >
        {{ authAccount.msg }}
      </div>
      <div class="sa360AccountInfo" v-if="sa360AccountInfo">
        <div class="row">
          <div class="col-sm-7">
            <div class="form-group">
              <label class="col-form-label">Advertiser Name</label>
              <input
                type="text"
                class="form-control"
                :value="sa360AccountInfo.advertiserName"
                disabled
              />
            </div>
            <div class="form-group">
              <label class="col-form-label">Agency Name</label>
              <input
                type="text"
                class="form-control"
                :value="sa360AccountInfo.agencyName"
                disabled
              />
            </div>
            <div class="form-group">
              <label class="col-form-label">sFTP Username</label>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': validUsername }"
                v-model="sftpUsername"
              />
              <div class="invalid-feedback" v-show="validUsername">
                Please provide a valid username.
              </div>
            </div>
            <div class="form-group">
              <label class="col-form-label">sFTP Password</label>
              <input
                type="password"
                class="form-control"
                :class="{ 'is-invalid': validPassword }"
                v-model="sftpPassword"
              />
              <div class="invalid-feedback" v-show="validPassword">
                Please provide a valid username.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="google-EDIT" v-if="connectionType === 'Edit'">
      <div class="row">
        <div class="col-sm-7">
          <div class="form-group">
            <label class="col-form-label">Google Account</label>
            <input
              type="text"
              class="form-control"
              disabled
              :value="view.sa360Account.googleAccount.name"
            />
          </div>
          <div class="form-group">
            <label class="col-form-label">SA360 Account</label>
            <input
              type="text"
              class="form-control"
              disabled
              :value="view.sa360Account.accountName"
            />
          </div>
          <div class="form-group">
            <label class="col-form-label">Advertiser Name</label>
            <input
              type="text"
              class="form-control"
              :value="view.sa360Account.advertiserName"
              disabled
            />
          </div>
          <div class="form-group">
            <label class="col-form-label">Agency Name</label>
            <input
              type="text"
              class="form-control"
              :value="view.sa360Account.agencyName"
              disabled
            />
          </div>
          <div class="form-group">
            <label class="col-form-label">sFTP Username</label>
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': validUsername }"
              v-model="sftpUsername"
            />
            <div class="invalid-feedback" v-show="validUsername">
              Please provide a valid username.
            </div>
          </div>
          <div class="form-group">
            <label class="col-form-label">sFTP Password</label>
            <input
              type="password"
              class="form-control"
              :class="{ 'is-invalid': validPassword }"
              v-model="sftpPassword"
            />
            <div class="invalid-feedback" v-show="validPassword">
              Please provide a valid username.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import InputLoading from '../components/InputLoading'

export default {
  name: 'GoogleAuth',
  components: { InputLoading },
  props: {
    connectionType: { type: String, required: true },
    validUsername: Boolean,
    validPassword: Boolean,
    messageEventStatus: Boolean
  },
  data() {
    return {
      domainPath: process.env.VUE_APP_DOMAIN,
      accountId: '',
      adAccountLoading: false,
      errorMsg: {
        getAccounts: '',
        getSa360Accounts: ''
      },
      authAccount: {
        status: false,
        msg: ''
      },
      openWindow: undefined,
      openWindowTimer: undefined
    }
  },
  computed: {
    ...mapState({
      view: state => state.connection.view,
      edit: state => state.connection.edit,
      accounts: state => state.google.accounts,
      sa360Accounts: state => state.google.sa360Accounts
    }),
    googleAccountId: {
      get() {
        return this.edit.googleAccountId
      },
      set(value) {
        this['connection/setEditSetup']({ type: 'googleAccountId', value })
        this.changeAdAccount({ id: value })
      }
    },
    sa360AccountId: {
      get() {
        return this.edit.sa360AccountId
      },
      set(value) {
        this['connection/setEditSetup']({ type: 'sa360AccountId', value })
      }
    },
    sftpUsername: {
      get() {
        return this.edit.sftpUsername
      },
      set(value) {
        this['connection/setEditSetup']({ type: 'sftpUsername', value })
      }
    },
    sftpPassword: {
      get() {
        return this.edit.sftpPassword
      },
      set(value) {
        this['connection/setEditSetup']({ type: 'sftpPassword', value })
      }
    },
    sa360AccountInfo() {
      return this.sa360Accounts
        ? this.sa360Accounts.find(x => x.id === this.sa360AccountId)
        : undefined
    }
  },
  methods: {
    ...mapActions([
      'progressStart',
      'progressEnd',
      'google/getAccounts',
      'google/getSa360Accounts',
      'google/getOauthStart'
    ]),
    ...mapMutations(['connection/setEditSetup']),
    getGoogleAuth() {
      this.authAccount.status = ''
      this.authAccount.msg = ''
      this.$emit('update:messageEventStatus', true)

      this.openAuth()
    },
    async openAuth() {
      this.openWindow = window.open(
        '',
        '_blank',
        'width=500,height=700,top=500,left=500'
      )

      if (process.env.NODE_ENV === 'development') {
        this.openWindow.location = `/pages/authResponseTest?type=google`
      } else if (process.env.NODE_ENV === 'production') {
        let response = await this['google/getOauthStart']()
        if (response.status === 201) {
          const url = response.data.url
          this.openWindow.location = url
        }
      }

      this.messageAddEvent()
    },
    messageAddEvent() {
      window.addEventListener('message', this.googleAuthMsg, false)
      this.openWindowTimer = setInterval(this.listenerOpenWindowClose, 100)
    },
    messageRemoveEvent() {
      window.removeEventListener('message', this.googleAuthMsg, false)
      this.$emit('update:messageEventStatus', false)
    },
    googleAuthMsg(e) {
      if (e.origin !== this.domainPath) {
        this.messageRemoveEvent()
        return false
      } else {
        let { status, type } = e.data
        if (status === 'success' && type === 'google') {
          this.authAccount.status = 'success'
          this.authAccount.msg = 'google authorisation success'
          this.getAccounts()
          this.messageRemoveEvent()
        } else if (status === 'error' && type === 'google') {
          this.authAccount.status = 'error'
          this.authAccount.msg =
            'google authorisation failed, please try it again!'
          this.messageRemoveEvent()
        }
      }
    },
    listenerOpenWindowClose() {
      if (this.openWindow.closed === true) {
        this.messageRemoveEvent()
        clearInterval(this.openWindowTimer)
        return
      }
    },
    async changeAdAccount({ id }) {
      this.adAccountLoading = true
      this.errorMsg.getSa360Accounts = ''
      let status = await this['google/getSa360Accounts']({
        id
      })
      if (status === 200) {
        if (this.sa360Accounts.length) {
          this.sa360AccountId = this.sa360Accounts[0].id
        } else {
          this.errorMsg.getSa360Accounts = 'no sa360 account.'
        }
      } else {
        this.errorMsg.getSa360Accounts =
          'get ad account fail. please check your google account and it again.'
      }
      this.adAccountLoading = false
    },
    async getAccounts() {
      this['progressStart']()
      this.errorMsg.getAccounts = ''
      let status = await this['google/getAccounts']()
      if (status === 200) {
        if (this.accounts.length) {
          this.googleAccountId = this.accounts[0].id
          this.changeAdAccount({ id: this.googleAccountId })
        } else {
          this.authAccount.status = 'error'
          this.authAccount.msg = 'google authorisation success. But No Account'
        }
        this['progressEnd']()
      } else {
        this.errorMsg.getAccounts =
          'get account fail. please check your google account and it again.'
        this['progressEnd']()
      }
    },
    async refreshAccount() {
      // TODO: - 等候端給API
      await this.getAccounts()
    }
  }
}
</script>
