<template>
  <div class="facebook-auth">
    <div class="facebook-NEW" v-if="connectionType === 'New'">
      <div class="form-group" v-if="!accounts.length">
        <button
          class="btn btn-primary btn-auth"
          @click.prevent="getFacebookAuth"
          :disabled="messageEventStatus"
        >
          Authorisation Facebook
        </button>
      </div>
      <div class="form-group" v-if="accounts.length">
        <label class="col-form-label">Facebook Account</label>
        <div class="row">
          <div class="col-sm-7">
            <div class="form-group">
              <select
                class="form-control"
                :class="{
                  'is-invalid': errorMsg.getAccounts
                }"
                v-model="facebookAccountId"
              >
                <option
                  v-for="option of accounts"
                  :key="option.id"
                  :value="option.id"
                  >{{ option.name }}</option
                >
              </select>
            </div>
          </div>
          <div class="col-sm-5">
            <button
              class="btn btn-primary btn-addAccount"
              v-b-tooltip.hover
              title="Add Account"
              @click.prevent="getFacebookAuth"
              :disabled="messageEventStatus"
            >
              <span class="material-icons" style="font-size:19px;">add</span>
            </button>
            <button
              class="btn btn-primary btn-addAccount"
              v-b-tooltip.hover
              title="Refresh Account"
              @click.prevent="refreshAccount"
            >
              <span class="material-icons" style="font-size:19px;"
                >refresh</span
              >
            </button>
          </div>
        </div>
        <div class="invalid-feedback" v-show="errorMsg.getAccounts">
          {{ errorMsg.getAccounts }}
        </div>
      </div>
      <div class="form-group" v-if="accounts.length">
        <label class="col-form-label">Facebook Ad Account</label>
        <div class="row form-group">
          <div class="col-sm-7">
            <div class="loading-container">
              <select
                class="form-control"
                :class="{
                  'is-invalid': errorMsg.getAdAccounts
                }"
                v-model="facebookAdAccountId"
              >
                <option
                  v-for="option of adAccounts"
                  :key="option.id"
                  :value="option.id"
                  >{{ option.accountName }}</option
                >
              </select>
              <InputLoading :active.sync="adAccountLoading" :size="20" />
            </div>
          </div>
        </div>
        <div class="invalid-feedback" v-show="errorMsg.getAdAccounts">
          {{ errorMsg.getAdAccounts }}
        </div>
      </div>
      <div
        class="invalid-feedback"
        :class="
          authAccount.status === 'success' ? 'text-success' : 'text-danger'
        "
        v-show="authAccount.msg"
      >
        {{ authAccount.msg }}
      </div>
    </div>
    <div class="facebook-EDIT" v-if="connectionType === 'Edit'">
      <div class="form-group">
        <div class="row">
          <div class="col-sm-7">
            <div class="form-group">
              <label class="col-form-label">Facebook Account</label>
              <input
                class="form-control"
                type="text"
                disabled
                :value="view.facebookAdAccount.facebookAccount.name"
              />
            </div>
            <div class="form-group">
              <label class="col-form-label">Facebook Ad Account</label>
              <input
                class="form-control"
                type="text"
                disabled
                :value="view.facebookAdAccount.accountName"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import InputLoading from '../components/InputLoading'

export default {
  name: 'FacebookAuth',
  components: { InputLoading },
  props: {
    connectionType: { type: String, required: true },
    messageEventStatus: Boolean
  },
  data() {
    return {
      domainPath: process.env.VUE_APP_DOMAIN,
      accountId: '',
      adAccountLoading: false,
      errorMsg: {
        getAccounts: '',
        getAdAccounts: ''
      },
      authAccount: {
        status: false,
        msg: ''
      },
      openWindow: undefined,
      openWindowTimer: undefined
    }
  },
  computed: {
    ...mapState({
      view: state => state.connection.view,
      edit: state => state.connection.edit,
      accounts: state => state.facebook.accounts,
      adAccounts: state => state.facebook.adAccounts
    }),
    facebookAccountId: {
      get() {
        return this.edit.facebookAccountId
      },
      set(value) {
        this['connection/setEditSetup']({ type: 'facebookAccountId', value })
        this.changeAdAccount({ id: value })
      }
    },
    facebookAdAccountId: {
      get() {
        return this.edit.facebookAdAccountId
      },
      set(value) {
        this['connection/setEditSetup']({
          type: 'facebookAdAccountId',
          value
        })
        this.timezoneChange()
      }
    }
  },
  methods: {
    ...mapActions([
      'progressStart',
      'progressEnd',
      'facebook/getAccounts',
      'facebook/getAdAccounts',
      'facebook/getOauthStart'
    ]),
    ...mapMutations(['connection/setEditSetup']),
    getFacebookAuth() {
      this.authAccount.status = ''
      this.authAccount.msg = ''
      this.$emit('update:messageEventStatus', true)

      this.openAuth()
    },
    async openAuth() {
      this.openWindow = window.open(
        '',
        '_blank',
        'width=500,height=700,top=500,left=500'
      )
      if (process.env.NODE_ENV === 'development') {
        this.openWindow.location = `/pages/authResponseTest?type=facebook`
      } else if (process.env.NODE_ENV === 'production') {
        let response = await this[`facebook/getOauthStart`]()
        if (response.status === 201) {
          const url = response.data.url
          this.openWindow.location = url
        }
      }

      this.messageAddEvent()
    },
    messageAddEvent() {
      window.addEventListener('message', this.facebookAuthMsg, false)
      this.openWindowTimer = setInterval(this.listenerOpenWindowClose, 100)
    },
    messageRemoveEvent() {
      window.removeEventListener('message', this.facebookAuthMsg, false)
      this.$emit('update:messageEventStatus', false)
    },
    facebookAuthMsg(e) {
      if (e.origin !== this.domainPath) {
        this.messageRemoveEvent()
        return false
      } else {
        let { status, type } = e.data
        if (status === 'success' && type === 'facebook') {
          this.authAccount.status = 'success'
          this.authAccount.msg = 'facebook authorisation success'
          this.getAccounts()
          this.messageRemoveEvent()
        } else if (status === 'error' && type === 'facebook') {
          this.authAccount.status = 'error'
          this.authAccount.msg =
            'facebook authorisation failed, please try it again!'
          this.messageRemoveEvent()
        }
      }
    },
    listenerOpenWindowClose() {
      if (this.openWindow.closed === true) {
        this.messageRemoveEvent()
        clearInterval(this.openWindowTimer)
        return
      }
    },
    timezoneChange() {
      const adAccountId = this.edit.facebookAdAccountId
      if (adAccountId) {
        const adAccount = this.adAccounts.filter(x => x.id === adAccountId)
        this['connection/setEditSetup']({
          type: 'timezone',
          value: adAccount[0].timezone
        })
      }
    },
    async changeAdAccount({ id }) {
      this.adAccountLoading = true
      this.errorMsg.getAdAccounts = ''
      let status = await this['facebook/getAdAccounts']({
        id
      })
      if (status === 200) {
        if (this.adAccounts.length) {
          this.facebookAdAccountId = this.adAccounts[0].id
        } else {
          this.errorMsg.getAdAccounts = 'no ad account.'
        }
      } else {
        this.errorMsg.getAdAccounts =
          'get ad account fail. please check your facebook account and it again.'
      }
      this.adAccountLoading = false
    },
    async getAccounts() {
      this['progressStart']()
      this.errorMsg.getAccounts = ''
      let status = await this['facebook/getAccounts']()
      if (status === 200) {
        if (this.accounts.length) {
          this.facebookAccountId = this.accounts[0].id
          // this.changeAdAccount({ id: this.facebookAccountId })
        } else {
          this.authAccount.status = 'error'
          this.authAccount.msg =
            'facebook authorisation success. But No Account'
        }
        this['progressEnd']()
      } else {
        this.errorMsg.getAccounts =
          'get account fail. please check your facebook account and it again.'
        this['progressEnd']()
      }
    },
    async refreshAccount() {
      // TODO: - 等候端給API
      await this.getAccounts()
    }
  }
}
</script>
