<template>
  <main class="connectionsEdit">
    <b-breadcrumb :items="breadcrumb" />
    <div class="container" :class="{ 'mx-auto': !sidebarShow }">
      <SectionUserInfo v-if="forClient" />
      <section class="section setup">
        <div class="section-title connectionsEdit-title">
          Connection {{ connectionType }}
        </div>
        <div class="setup-form row" v-if="formShow">
          <form class="col-lg-8">
            <div class="setup-block row">
              <div class="col-sm-3">
                <h6 class="setup-block-title">Source</h6>
              </div>
              <div class="col-sm-9">
                <FacebookAuth
                  :connectionType="connectionType"
                  :messageEventStatus.sync="messageEventStatus"
                />
              </div>
            </div>
            <div class="setup-block row">
              <div class="col-sm-3">
                <h6 class="setup-block-title">Destination</h6>
              </div>
              <div class="col-sm-9">
                <GoogleAuth
                  :connectionType="connectionType"
                  :validUsername="validUsername"
                  :validPassword="validPassword"
                  :messageEventStatus.sync="messageEventStatus"
                />
              </div>
            </div>
            <div class="setup-block row schedule">
              <div class="col-sm-3">
                <h6 class=" setup-block-title">Schedule</h6>
              </div>
              <div class="col-sm-9">
                <div class="schedule-text" v-if="connectionTimezone">
                  {{ connectionTimezone }}
                  <span class="schedule-note">6AM</span>
                </div>
              </div>
            </div>
            <div class="setup-block row">
              <label class="col-sm-3 col-form-label"></label>
              <div class="col-sm-9">
                <div class="form-group">
                  <button
                    class="btn btn-secondary btn-cancel"
                    @click.prevent="modalShowHide"
                  >
                    Cancel
                  </button>
                  <button
                    class="btn btn-primary btn-save"
                    @click.prevent="SubmitHandler"
                  >
                    {{ connectionType === 'New' ? 'Create' : 'Save' }}
                  </button>
                </div>
              </div>
            </div>
            <div class="alert alert-danger" role="alert" v-show="errorMsg">
              {{ errorMsg }}
            </div>
          </form>
          <!-- <div class="sftp-credential col-lg-4">
            <ConnectionsViewSetup />
          </div> -->
        </div>
      </section>
    </div>
    <ModalCancelConfirm
      :isOpen.sync="cancelModalShow"
      @yes="goBackHandler"
      @no="modalShowHide"
    />
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SectionUserInfo from '../components/SectionUserInfo.vue'
import FacebookAuth from '../components/FacebookAuth.vue'
import GoogleAuth from '../components/GoogleAuth.vue'
// import ConnectionsViewSetup from '../components/ConnectionsViewSetup.vue'
import ModalCancelConfirm from '../components/ModalCancelConfirm.vue'

export default {
  name: 'ConnectionsSetup',
  components: {
    SectionUserInfo,
    FacebookAuth,
    GoogleAuth,
    // ConnectionsViewSetup,
    ModalCancelConfirm
  },
  data() {
    return {
      connectionType: undefined,
      formShow: false,
      errorMsg: '',
      messageEventStatus: false,
      validUsername: false,
      validPassword: false,
      cancelModalShow: false
    }
  },
  async mounted() {
    let id = this.$route.params.id
    id ? (this.connectionType = 'Edit') : (this.connectionType = 'New')
    let status = await this['connection/pageSetupCreated']({ id })
    if (status === 'success') {
      this.formShow = true
    } else {
      console.log('connectionSetup created: ' + status)
      this.$router.push('/pages/page403')
    }
  },
  computed: {
    ...mapState({
      sidebarShow: state => state.sidebarShow,
      user: state => state.user.self,
      connectionEdit: state => state.connection.edit,
      connectionTimezone: state => state.connection.edit.timezone
    }),
    breadcrumb() {
      return [
        {
          text: 'Reporting Connections',
          to: '/connections'
        },
        {
          text: this.connectionType,
          active: true
        }
      ]
    },
    forClient() {
      return this.user.role === 'CLIENT'
    }
  },
  methods: {
    ...mapActions([
      'connection/pageSetupCreated',
      'connection/pageSetupSubmit'
    ]),
    validationNew() {
      const validationData = val => {
        return val ? true : false
      }

      let facebookAdAccountId = validationData(
        this.connectionEdit.facebookAdAccountId
      )

      let sa360AccountId = validationData(this.connectionEdit.sa360AccountId)

      let sftpUsername = validationData(this.connectionEdit.sftpUsername)
      sftpUsername ? (this.validUsername = false) : (this.validUsername = true)

      let sftpPassword = validationData(this.connectionEdit.sftpPassword)
      sftpPassword ? (this.validPassword = false) : (this.validPassword = true)

      return (
        facebookAdAccountId && sa360AccountId && sftpUsername && sftpPassword
      )
    },
    async SubmitHandler() {
      this.errorMsg = ''
      let validForm =
        this.connectionType === 'New' ? this.validationNew() : true

      if (validForm) {
        let response = await this['connection/pageSetupSubmit']({
          status: this.connectionType,
          id: this.$route.params.id
        })
        this.saveDone(response)
      } else {
        this.errorMsg = 'Please check form and try it again.'
      }
    },
    saveDone(response) {
      if (response === 'success') {
        this.$router.push('/connections')
      } else {
        this.errorMsg = response
      }
    },
    modalShowHide() {
      this.cancelModalShow = !this.cancelModalShow
    },
    goBackHandler() {
      this.$router.push('/connections')
    }
  }
}
</script>
