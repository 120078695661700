<template>
  <div
    class="loading-container input-loading"
    :style="{ width: `${size}px`, height: `${size}px` }"
  >
    <Loading
      :active.sync="active"
      :is-full-page="false"
      transition="fade"
      :width="size - 2"
      :height="size - 2"
      :opacity="0.75"
      background-color="#fff"
    ></Loading>
  </div>
</template>
<script>
export default {
  name: 'InputLoading',
  props: {
    active: { type: Boolean, required: true },
    size: { type: Number, required: true }
  },
  data() {
    return {}
  }
}
</script>
